import { apiRequest } from "./util";

export function searchCases(data) {
    return apiRequest({path: '/ch/hubcases', method: "GET", data: data});
}

export function createCase({title, content, key_question, is_anonymous}) {
    return apiRequest({path: '/ch/hubcase', method: "POST", data: {title, content, key_question, is_anonymous}});
}

export function fetchCaseById(id) {
    return apiRequest({path: `/ch/hubcase/${id}`, method: "GET"});
}

export function fetchCasesByStatus({
    status = 'NEW',
    limit,
    orderBy = 'created',
    sortOrder,
    offset,
    category,
    onlyFuture,
    onlyPast,
    onlyMine
}, ctx) {
    const params = {
        state: status,
        limit: limit,
        offset: offset,
        category_id: category,
        order_by: orderBy,
        sort_order: sortOrder,
        only_future: onlyFuture,
        only_past: onlyPast,
        me_registered: onlyMine
    };

    const urlParams = Object.entries(params).reduce((acc, [key, value], index) => {
        if (value) {
            acc += `${index === 0 ? '' : '&'}${key}=${value}`
        }
        return acc;
    }, '');

    return apiRequest({path: `/ch/hubcases?${urlParams}`, method: "GET", ctx});
}

export function toggleCaseRegistration(id) {
    return apiRequest({path: `/ch/hubcase/${id}/register`, method: "POST"});
}

export function likeCase(id) {
    return apiRequest({path: `/ch/hubcase/${id}/like`, method: "POST"});
}

export function fetchLikesForCase(id) {
    return apiRequest({path: `/ch/hubcase/${id}/like`, method: "GET"});
}

export function commentCase(id, parentId, content) {
    return apiRequest({path: `/ch/hubcase/${id}/comment`, method: "POST", data: {content, parent_id: parentId}});
}

export function fetchCaseChildComments(caseId, commentId) {
    return apiRequest({path: `/ch/hubcase/${caseId}/childcomments?parent_id=${commentId}`, method: "GET"});
}

export function fetchCalendarInvite(id) {
    return apiRequest({path: `/ch/hubcase/${id}/invite`, method: "GET", responseFormat: 'blob', allowRedirect: false});
}

export function shouldShowJoinCaseButton({user_participant, scheduled_to, zoom_us_link, duration}) {
    if (!user_participant || !zoom_us_link) {
        return false;
    }

    const now = Date.now() / 1000;

    const durationInSeconds = (duration || 90) * 60;
    const isExpired = (scheduled_to + durationInSeconds) < now;

    if (isExpired) {
        return false;
    }

    return (scheduled_to - now) < 20 * 60;
}
