import {apiRequest} from './util';

export const fetchTrainingPlanList = async (ctx) => {
    const list = await apiRequest({path: '/ch/trainingplan/list', ctx});

    return list;
}

export const fetchTrainingPlanTypes = async (ctx) => {
    const list = await apiRequest({path: '/ch/trainingplan/types', ctx});

    return list;
}

export const fetchTrainingPlanById = async (id) => {
    const response = await apiRequest({path: `/ch/trainingplan/get/${id}`});

    return response;
}

export const addTrainingPlanResources = async (id, resources) => {
    const response = await apiRequest({
        path: `/ch/trainingplan/addresources/${id}`,
        method: 'POST',
        data: {
            resources
        }
    });

    return response;
}

export const removeTrainingPlanResource = async (id, resourceId) => {
    const response = await apiRequest({
        path: `/ch/trainingplan/removeresource/${id}`,
        method: 'POST',
        data: {
            resource_id: resourceId
        }
    });

    return response;
}

export const createTrainingPlan = async ({typeId, assessmentId, secondsPerWeek, resources, weeks, expertises}) => {
    return apiRequest({
        path: `/ch/trainingplan/create`, method: 'POST', data: {
            type_id: typeId,
            assessment_id: assessmentId,
            seconds_per_week: secondsPerWeek,
            expertises: expertises,
            weeks: weeks,
            resources
        }
    });
}

export const addExpertise = async (trainingPlanId, expertiseId) => {
    return apiRequest({
        path: `/ch/trainingplan/addexpertise/${trainingPlanId}`,
        method: 'POST',
        data: {
            expertise_id: expertiseId
        }
    });
}

export const removeExpertise = async (trainingPlanId, expertiseId) => {
    return apiRequest({
        path: `/ch/trainingplan/removeexpertise/${trainingPlanId}`,
        method: 'POST',
        data: {
            expertise_id: expertiseId
        }
    });
}

export const toggleTrainingPlanResourceCompleted = async ({resourceId, trainingPlanId}) => {
    return apiRequest({
        path: `/ch/trainingplan/resourcetoggle/${trainingPlanId}`,
        method: 'POST',
        data: {
            resource_id: resourceId
        }
    });
}

export const trainingPlanResourceComplete = async ({resourceId, trainingPlanId}) => {
    return apiRequest({
        path: `/ch/trainingplan/resourcecompleted/${trainingPlanId}`,
        method: 'POST',
        data: {
            resource_id: resourceId
        }
    });
}

export const trainingPlanResourceUnComplete = async ({resourceId, trainingPlanId}) => {
    return apiRequest({
        path: `/ch/trainingplan/resourceuncompleted/${trainingPlanId}`,
        method: 'POST',
        data: {
            resource_id: resourceId
        }
    });
}