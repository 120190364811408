import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-full text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 gap-1",
  {
    variants: {
      variant: {
        default: "bg-buttonPrimary text-buttonPrimary-foreground rounded-full hover:bg-buttonPrimary-hover",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive-hover",
        outline:
          "border border-gray-700 hover:bg-gray-700 hover:text-white",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-gray-200 hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        plain: "bg-gray-100 hover:bg-gray-200",
        badge: "bg-gray-100 hover:bg-gray-200 text-gray-600",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-full px-3",
        lg: "h-10 rounded-full px-5",
        icon: "h-10 w-10",
        subtle: "h-6 rounded-full px-3",
      },
      environment: {
          dark: ""
      }
    },
    compoundVariants: [
        {
            variant: "outline",
            environment: "dark",
            className: "border border-gray-50 hover:border-gray-700 hover:bg-gray-700 text-white"
        }
    ],
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, environment, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, environment, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
