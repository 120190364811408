import {useEffect, useState, useCallback, useMemo} from 'react';
import {fetchTrainingPlanById, fetchTrainingPlanList, fetchTrainingPlanTypes} from '../trainingplan';
import {zonedTimeToUtc} from 'date-fns-tz';
import {formatDate, FormatName} from '../format';
import {useAuth} from '../auth';
import {useStableCallback} from '@/util/hooks/useStableCallback';
import {TrainingPlan} from "@/types/trainigplan";

enum Statuses {
    Loading = 'loading',
    Success = 'success',
}

export const useTrainingPlanList = () => {
    const [status, setStatus] = useState<Statuses | null>(null);
    const [plans, setPlans] = useState<TrainingPlan[]>([]);
    const auth = useAuth();

    const isAuthenticated = auth.isAuthenticated;

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                setStatus(Statuses.Loading);
                const userTrainingPlansResponse = await fetchTrainingPlanList();
                const userTrainingPlans: TrainingPlan[] = userTrainingPlansResponse?.trainingplans || [];
                userTrainingPlans.sort((a, b) => b.created - a.created)
                setPlans(userTrainingPlans);
                setStatus(Statuses.Success);
            }
        })();
    }, [setPlans, setStatus, isAuthenticated]);

    return {
        plans,
        status,
    }
}

export const useLoadTrainingPlan = (planId: string) => {
    const [status, setStatus] = useState<Statuses | null>(null);
    const [plan, setPlan] = useState<TrainingPlan | null>(null);
    const auth = useAuth();
    
    useEffect(() => {
        if (planId) {
            setStatus(Statuses.Loading);
            _fetchTrainingPlanById(planId, auth?.user?.timezone).then((plan) => {
                if (plan) {
                    setPlan(plan);
                    setStatus(Statuses.Success);
                }
            })
        }
    }, [planId]);
    
    const reloadTrainingPlanById = useStableCallback(async (planId) => {
        const plan = await _fetchTrainingPlanById(planId, auth?.user?.timezone);

        if (plan) {
            setPlan(plan);
        }
    });
    
    return {
        plan,
        status,
        reloadTrainingPlanById
    }
}

const _fetchTrainingPlanById = async (planId: string, tz: string) => {
    const trainingPlanResponse = await fetchTrainingPlanById(planId);
    const plan = trainingPlanResponse?.trainingplan as unknown as TrainingPlan | null;
    
    if (!plan) {
        return;
    }
    
    const dayInSeconds = 86400;
    const weekInSeconds = 7 * dayInSeconds;
    const startTs = zonedTimeToUtc(plan?.start_date?.date, plan?.start_date?.timezone).getTime() / 1000;
    const durationInWeeks = Math.ceil(plan?.duration_days / 7);
    const endTs = startTs + durationInWeeks * weekInSeconds;
    
    return {
        ...plan,
        startTs,
        endTs,
        startTsHr: formatDate({ts: startTs, tz: tz, format: FormatName.Medium}),
        endTsHr: formatDate({ts: endTs, tz: tz, format: FormatName.Medium}),
    }
};

export const useTrainingPlan = () => {
    const {plans, status: listStatus} = useTrainingPlanList();
    const currentPlanId = plans[0]?.id;
    const {plan, status, reloadTrainingPlanById} = useLoadTrainingPlan(currentPlanId);

    const getIsResourcePartOfTheTrainingPlan = (resourceId: string) => {
        return (plan?.resources || []).some(r => r.resource_id === resourceId);
    };

    const getIsResourceCompleted = (resourceId: string) => {
        const resource = (plan?.resources || []).find(r => r.resource_id === resourceId);
        return !!(resource && resource.completed);
    }

    let finalStatus: Statuses | null;

    if (listStatus !== Statuses.Success) {
        finalStatus = listStatus;
    } else if (!currentPlanId) {
        finalStatus = Statuses.Success;
    } else {
        finalStatus = status;
    }

    return {
        plan,
        status: finalStatus,
        getIsResourcePartOfTheTrainingPlan,
        getIsResourceCompleted,
        reloadTrainingPlanById
    } as const;
}
