import React, {createContext, useContext, useEffect, useMemo, useState,} from "react";
import router, {useRouter} from "next/router";
import PageLoader from "../components/PageLoader";
import {apiRequest, CustomError} from './util';
import {fetchCurrentUser} from './users';
import {User} from '@/types/user';
import {ExpertiseWithGroupId} from "@/types/assessment";

const authenticate = async ({username, password, recaptchaToken, hash}: {
    username: string;
    password: string;
    recaptchaToken: string;
    hash: string;
}) => {
  return await apiRequest({
    path: '/auth/login', method: 'POST', data: {
      username,
      password,
      hash,
      recaptcha_token: recaptchaToken
    }
  });
}

const signup = async ({
  email,
  password,
  firstName,
  lastName,
  companyName,
  position,
  phone,
  terms,
  timezone,
  keepPrivate,
  codeOfConduct,
  recaptchaToken,
  assessmentData,
  referrer
}: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
    position: string;
    phone: string;
    terms: boolean;
    timezone: string;
    keepPrivate: boolean;
    codeOfConduct: boolean;
    recaptchaToken: string;
    assessmentData?: any;
    referrer?: string;
}) => {
  type RequestData = {
    email: string;
    password: string;
    password_repeat: string;
    first_name: string;
    last_name: string;
    company_name: string;
    position: string;
    phone: string;
    terms: boolean;
    keep_private: boolean;
    code_of_conduct: boolean;
    timezone: string;
    recaptcha_token: string;
    assessment_form?: any;
    referrer?: string;
  }
  const requestData: RequestData = {
    email,
    password,
    password_repeat: password,
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    position: position,
    phone: phone,
    terms: terms,
    keep_private: keepPrivate,
    code_of_conduct: codeOfConduct,
    timezone: timezone,
    recaptcha_token: recaptchaToken,
    referrer: referrer
  }

  if (assessmentData) {
    requestData.assessment_form = assessmentData;
  }

  return await apiRequest({
    path: '/ch/user/me/create', method: 'POST', data: requestData
  });
}

const forgotpass = async ({email, recaptchaToken}: {
    email: string;
    recaptchaToken: string;
}) => {
  return await apiRequest({
    path: '/ch/user/me/forgotpassword', method: 'POST', data: {
      email,
      recaptcha_token: recaptchaToken
    }
  });
}

const resetpass = async ({new_password1, new_password2, recaptcha_token, hash}: {
    new_password1: string;
    new_password2: string;
    recaptcha_token: string;
    hash: string;
}) => {
  return await apiRequest({
    path: '/ch/user/me/password', method: 'POST', data: {
      new_password1,
      new_password2,
      recaptcha_token,
      hash
    }
  });
}

const logout = async () => {
  return await apiRequest({
    path: '/auth/logout', method: 'POST'});
}

const realAuth = {
  signin: async ({username, password, recaptchaToken, hash}: {
        username: string;
        password: string;
        recaptchaToken: string;
        hash: string;
  }) => {
    await authenticate({username, password, recaptchaToken, hash});

    const user = await fetchCurrentUser();

    return user;
  },
  signup: async ({
    email,
    password,
    firstName,
    lastName,
    companyName,
    position,
    phone,
    timezone,
    terms,
    keepPrivate,
    codeOfConduct,
    recaptchaToken,
    assessmentData,
    referrer
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
    position: string;
    phone: string;
    timezone: string;
    terms: boolean;
    keepPrivate: boolean;
    codeOfConduct: boolean;
    recaptchaToken: string;
    assessmentData?: any;
    referrer?: string;
  }) => {
    await signup({
      email,
      password,
      firstName,
      lastName,
      companyName,
      position,
      phone,
      terms,
      timezone,
      codeOfConduct,
      keepPrivate,
      recaptchaToken,
      assessmentData,
      referrer
    });
  },
  signout: async () => {
    return await logout();
  },
  forgotpass: async ({email, recaptchaToken}: {
        email: string;
        recaptchaToken: string;
  }) => {
    return await forgotpass({email, recaptchaToken})
  },
  resetpass: async ({new_password1, new_password2, recaptcha_token, hash}: {
        new_password1: string;
        new_password2: string;
        recaptcha_token: string;
        hash: string;
  }) => {
    return await resetpass({new_password1, new_password2, recaptcha_token, hash})
  }
}

export enum AuthStates {
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

interface AuthContextType {
  user?: User | null | false;
  isAuthenticated?: boolean;
  signin: ({username, password, recaptchaToken, hash}: {
        username: string;
        password: string;
        recaptchaToken?: string;
        hash?: string;
  }) => Promise<User>;
  isIncludedInSubscription: (subscription: string) => boolean;
  getExpertises: () => ExpertiseWithGroupId[];
}

const authContext = createContext<AuthContextType>({});

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function AuthProvider({ children, user }: {
    children: React.ReactNode;
    user: User;
}) {
  const auth = useAuthProvider({initialUser: user});
  return <authContext.Provider value={auth}>{auth.user === null ? null : children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

export const SubscriptionStatuses = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  NONE: 'NONE',
}

function useAuthProvider({initialUser = null}: {
    initialUser?: User | null;
}) {
  const [user, setUser] = useState<User | null | false>(initialUser);
  const router = useRouter();
  const finalUser = usePrepareUser(user);

  const handleAuth = async (user: User) => {
    if (user.id) {
      setUser(user);
    } else {
      throw new CustomError(403, 'User not found');
    }
    return user;
  };

  const reloadUser = async () => {
    const user = await fetchCurrentUser();
    if (user.id) {
      setUser(user);
    }
  }

  const signin = ({username, password, hash, recaptchaToken}: {
    username: string;
    password: string;
    hash: string;
    recaptchaToken: string;
  }) => {
    return realAuth
      .signin({username, password, hash, recaptchaToken})
      .then((user) => handleAuth(user));
  };

  const signout = () => {
    return realAuth
    .signout()
    .then(() => {
      setUser(false);
    });
  };

  const signup = (props) => {
    return realAuth.signup(props)
  };

  const forgotpass = (props) => {
    return realAuth.forgotpass(props)
  };

  const resetpass = (props) => {
    return realAuth.resetpass(props)
  };

  const isIncludedInSubscription = (feature: string) => {
    if (!finalUser) {
        return false;
    }

    const featuresMap: {
        [key: string]: boolean;
    } = (finalUser?.subscription?.product?.features || []).reduce((acc: {
        [key: string]: boolean;
    }, {id, enabled}: {
        id: string;
        enabled: boolean;
    }) => {
      acc[id] = enabled;
      return acc;
    }, {});

    return !!featuresMap[feature];
  }

  const state = finalUser ? AuthStates.AUTHENTICATED : AuthStates.NOT_AUTHENTICATED;

  const redirectToLogin = ({next}: {
    next?: string;
  }) => {
    router.replace(`/auth/signin?next=${next || encodeURIComponent(router.asPath)}`);
  }

  const redirectToSignup = () => {
    router.replace(`/auth/signup`);
  }

  const redirectToPricing = () => {
    router.replace(`/pricing`);
  }

  const getExpertises = () => {
    return finalUser ? (finalUser?.expertises || []) : [];
  }

  return {
    user: finalUser,
    state: state,
    isAuthenticated: state === AuthStates.AUTHENTICATED,
    reloadUser,
    signin,
    signout,
    signup,
    forgotpass,
    resetpass,
    redirectToLogin,
    redirectToPricing,
    redirectToSignup,
    isIncludedInSubscription,
    getExpertises
  };
}

const enrichUser = (user: User) => {
  const futureSubscriptions = (user.future_subscriptions || []);

  const activeSubscription = user.subscription;
  const hasActiveSubscription = activeSubscription?.id;
  const isTrialSubscriptionActive = user.subscription?.is_trial;

  return {
    ...user,
    hasActiveSubscription: hasActiveSubscription,
    isTrialSubscriptionActive: isTrialSubscriptionActive,
    trialAvailable: !!user.trial_available,
    hasFutureSubscriptions: !!futureSubscriptions.length,
    futureSubscriptions: futureSubscriptions,
    futureSubscription: futureSubscriptions ? futureSubscriptions[0] : null
  };
}

function usePrepareUser(user: User | null | false) {
  return useMemo(() => {
    return user ? enrichUser(user) : user;
  }, [user]);
}


// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  const wrapper = (props) => {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        //TODO add ref for previous user
        router.replace(`/auth/signin?next=${encodeURIComponent(router.asPath)}`);
      }
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!auth.user) {
      return <PageLoader />;
    }

    return <Component {...props} />;
  };

  if (Component.getLayout) {
    wrapper.getLayout = Component.getLayout;
  }

  return wrapper;
};
